var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.hasAuthorization},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{staticClass:"mr-2",attrs:{"disabled":!_vm.hasAuthorization,"color":"primary","data-test":"addMember-btn"},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_vm._v(" Add Member ")])],1)]}}])},[_c('span',[_vm._v(" You don't have this kind of authorization. ")])]),_c('v-dialog',{attrs:{"max-width":"450"},on:{"click:outside":function($event){return _vm.close()}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"data-test":"namespaceNewMember-dialog"}},[_c('v-card-title',{staticClass:"headline primary"},[_vm._v(" Add member to namespace ")]),_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-card-text',{staticClass:"caption mb-0"},[_c('ValidationProvider',{ref:"providerUsername",attrs:{"name":"Username","rules":"required","vid":"username"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Username","error-messages":errors,"require":"","data-test":"username-text"},model:{value:(_vm.member.username),callback:function ($$v) {_vm.$set(_vm.member, "username", $$v)},expression:"member.username"}})]}}],null,true)})],1),_c('v-card-text',{staticClass:"caption mb-0 pt-0"},[_c('ValidationProvider',{ref:"providerRole",attrs:{"vid":"role","name":"role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.items,"label":"Role","error-messages":errors,"require":"","data-test":"role-select"},model:{value:(_vm.member.selectedRole),callback:function ($$v) {_vm.$set(_vm.member, "selectedRole", $$v)},expression:"member.selectedRole"}})],1)],1)]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","data-test":"close-btn"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"primary","text":"","data-test":"add-btn"},on:{"click":function($event){return passes(_vm.addMember)}}},[_vm._v(" Add ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }