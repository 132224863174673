var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('div',{staticClass:"mt-5"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.members,"hide-default-footer":"","data-test":"dataTable-field"},scopedSlots:_vm._u([{key:"item.username",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(" mdi-account ")]),_vm._v(" "+_vm._s(item.username)+" ")]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.role)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.id !== _vm.namespace.owner)?_c('v-menu',{ref:'menu'+_vm.members.indexOf(item),attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icons",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-horizontal ")])]}}],null,true)},[_c('v-list',[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.hasAuthorizationEditMember},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-list-item',{attrs:{"disabled":!_vm.hasAuthorizationEditMember},on:{"click":function($event){$event.stopPropagation();_vm.showNamespaceMemberFormEdit(_vm.members.indexOf(item))}}},[_c('NamespaceMemberFormDialogEdit',{attrs:{"member":item,"show":_vm.namespaceMemberFormShow[_vm.members.indexOf(item)],"data-test":"NamespaceMemberFormDialogEdit-component"},on:{"update:show":function($event){_vm.$set(_vm.namespaceMemberFormShow, _vm.members.indexOf(item), $event)},"update":_vm.refresh}})],1)],1)]}}],null,true)},[_c('span',[_vm._v(" You don't have this kind of authorization. ")])]),_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.hasAuthorizationRemoveMember},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-list-item',{attrs:{"disabled":!_vm.hasAuthorizationRemoveMember},on:{"click":function($event){$event.stopPropagation();_vm.showNamespaceMemberDelete(_vm.members.indexOf(item))}}},[_c('NamespaceMemberDelete',{attrs:{"member":item,"show":_vm.namespaceMemberDeleteShow[_vm.members.indexOf(item)],"data-test":"namespaceMemberDelete-component"},on:{"update:show":function($event){_vm.$set(_vm.namespaceMemberDeleteShow, _vm.members.indexOf(item), $event)},"update":_vm.refresh}})],1)],1)]}}],null,true)},[_c('span',[_vm._v(" You don't have this kind of authorization. ")])])],1)],1):_vm._e()]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }